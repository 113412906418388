/**
 * @function getLastLesson
 * @param {Array} assignedModules
 * Get the most recently assigned lesson
 */
function getLastLesson (assignedModules) {
  const allLessons = assignedModules.reduce(
    (acc, { assignedLessons }) => ({
      ...acc,
      ...assignedLessons
    }),
    {}
  )

  const sortedLessons = Object.keys(allLessons).sort(
    (a, b) => allLessons[b].ts - allLessons[a].ts
  )

  return sortedLessons[0]
}

export default getLastLesson
