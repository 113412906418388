import { firestoreConnect } from 'react-redux-firebase'
import { getFormDefaults } from 'utils'
import { trimValues, isPD } from 'shared/utils'
import { errorToMessage } from 'shared/utils/errors'
import formModal from 'components/formModal'
import { rpc, setUrl } from 'shared/actions'
import { schoolClasses } from 'selectors'
import { connect } from 'react-redux'
import schema from 'schema'
import { compose } from 'recompose'
import { message } from 'antd'

export default compose(
  connect(
    ({ firebase: { profile, auth } }, { school }) => ({
      district: profile.district,
      uid: auth.uid,
      school
    }),
    { rpc, setUrl }
  ),
  formModal({
    displayName: 'addToClass',
    mapPropsToValues: props => ({ class: undefined }),
    handleSubmit: async (values, { props }) => {
      props.setLoading(true)
      try {
        await props.rpc('class.addTeacher', cast(values, props))
        props.setUrl(`/class/${values.class}`)
        return props.onOk('Successfully joined class')
      } catch (e) {
        message.error(errorToMessage(e.error))
      }
      props.setLoading(false)
    },
    ...getFormDefaults(schema.class.addTeacher, cast)
  })
)

function cast (values, props) {
  return trimValues(values)
}
