import { useBugReportProvider } from 'providers/BugReportProvider'
import { Modal, Button, Icon, Row, Col } from 'antd'
import helpAnalyticsTracker from 'analytics/help'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import './HelpModal.less'

// let showCollectorAction

// window.ATL_JQ_PAGE_PROPS = {
//   triggerFunction: function (showCollectorDialog) {
//     showCollectorAction = showCollectorDialog
//   }
// }

const HelpModal = props => {
  const trackerSource = 'Help Modal'
  const analyticsTracker = helpAnalyticsTracker(trackerSource)
  const { showResources } = props

  return (
    <Modal
      {...props}
      footer={[
        <HelpModalFooter
          key='help-modal-footer'
          {...props}
          analyticsTracker={analyticsTracker}
        />
      ]}
      title='Need some help?'
      className='help-modal'
      visible
    >
      <p className='content'>
        {`If you have any questions, comments, or suggestions please ${
          showResources ? `check out our resources section or ` : ''
        }send us an email!`}
      </p>
      <Row
        type='flex'
        justify='center'
        className='help-btn-row'
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      >
        {showResources && (
          <Col>
            <Link to='/resources' rel='noopener noreferrer' target='_blank'>
              <Button
                icon='info-circle'
                type='primary green-class'
                onClick={analyticsTracker.teacherGuideView}
              >
                Resources
              </Button>
            </Link>
          </Col>
        )}
        <Col>
          <a
            href='mailto:help@9dots.org'
            rel='noopener noreferrer'
            target='_blank'
          >
            <Button
              icon='mail'
              type='primary green-class'
              onClick={analyticsTracker.emailUs}
            >
              Email Us
            </Button>
          </a>
        </Col>
      </Row>
    </Modal>
  )
}

const HelpModalFooter = props => {
  const showCollectorAction = useBugReportProvider()
  return (
    <Row
      className='help-modal-footer'
      justify='space-between'
      align='middle'
      type='flex'
    >
      <span
        className='report-bug-link'
        onClick={e => {
          e.preventDefault()
          props.analyticsTracker.reportBug()
          showCollectorAction()
        }}
      >
        <Icon type='exclamation-circle' />
        Report a Bug
      </span>
      <Button
        onClick={e => {
          props.analyticsTracker.cancelHelp()
          props.onCancel(e)
        }}
      >
        Cancel
      </Button>
    </Row>
  )
}

HelpModal.propTypes = {}

export default HelpModal
