import { trackPageViews, identify } from 'shared/actions'
import { profileMeta, uid } from '../../selectors'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import omit from '@f/omit'

export default compose(
  connect(
    state => ({
      profile: profileMeta(omit('nav', state.firebase.profile)),
      uid: uid(state)
    }),
    { trackPageViews, identify }
  ),
  lifecycle({
    componentWillMount() {
      this.props.history.listen(location => {
        this.props.trackPageViews(!!this.props.uid)
      })
    },
    componentWillUpdate(nextProps) {
      if (!isLoaded(this.props) && isLoaded(nextProps)) {
        this.props.identify({ ...nextProps.profile, uid: nextProps.uid })
        this.props.trackPageViews()
      }
    }
  })
)

function isLoaded(props) {
  return !props.profile.isEmpty && props.uid
}
