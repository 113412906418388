/**
 * @file help.js
 * @author Julius Diaz Panoriñgan
 * @author Giovanni Bonilla
 * File exports an object containing strings referring to help related actions
 * for passing into the 'useAnalyticsTracker' hook for analytics tracking.
 * File also exports a function that generates the set of actions that
 * trigger redux-segment middleware for help related analytics.
 *
 */

import { useAnalyticsTracker } from 'shared/analytics'

export const helpEvents = {
  helpModalOpen: 'OPENED_HELP_MODAL', // tracks opening of the help modal
  teacherGuideView: 'VIEWED_TEACHER_GUIDE', // tracks views of the teacher guide
  emailUs: 'CLICKED_EMAIL_US', // tracks following 'email us' links
  reportBug: 'CLICKED_REPORT_BUG', // tracks following 'report a bug' links
  cancelHelp: 'CANCELED_HELP_MODAL' // tracks canceling the help modal
}

export default defaultSource => useAnalyticsTracker(helpEvents, defaultSource)
