import { compose, withHandlers, onlyUpdateForKeys } from 'recompose'
import modalContainer from 'components/modalContainer'
import { schools, uid, profileMeta } from 'selectors'
import waitFor from 'shared/components/waitFor'
import { withRouter } from 'react-router-dom'
import { logout } from 'shared/ducks/user'
import { connect } from 'react-redux'
import { message } from 'antd'
import omit from '@f/omit'

export default compose(
  withRouter,
  modalContainer,
  connect(
    (state, { location }) => ({
      profile: profileMeta(omit('nav', state.firebase.profile)),
      schools: schools(omit('nav', state.firebase.profile)),
      pathname: location.pathname,
      uid: uid(state)
    }),
    { logout }
  ),
  withHandlers({
    onCreateModal: props => (msg, modal) => {
      props.hideModal(modal, null)
      message.success(msg)
    },
    logout: props => async () => {
      return props.logout(props.profile)
    }
  }),
  waitFor(['profile']),
  onlyUpdateForKeys(['isLoaded', 'pathname', 'profile'])
)
