import useSchoolMembersByRoles from 'shared/firestore/useSchoolMembersByRoles'
import { Modal, Divider, Row, Col, Select, Button, message } from 'antd'
import { useFirestoreValues } from 'shared/hooks/firestore'
import Field, { SelectField } from 'components/Field'
import { errorToMessage } from 'shared/utils/errors'
import { schoolRef } from 'shared/firestore/refs'
import formModal from 'components/formModal'
import { getFormDefaults } from 'utils'
import { rpc } from 'shared/utils/api'
import schema from 'schema'
import React from 'react'
import {
  getStudentNameWithId,
  isRosterSynced,
  alphaCompare,
  getFullName,
  trimValues,
  getId,
  isPD
} from 'shared/utils'

import './AddStudentModal.less'

// for formModal
// (placed here because it's closer to where a hook would be)
const formInfo = {
  displayName: 'addStudentToClass',
  mapPropsToValues: props => ({ class: undefined }),
  handleSubmit: async (values, { props }) => {
    props.setLoading(true)
    try {
      await rpc('class.addStudent', cast(values, props))
      return props.onOk('Success! Added student.')
    } catch (e) {
      message.error(errorToMessage(e.error))
    }
    props.setLoading(false)
  },
  ...getFormDefaults(schema.class.addStudent, cast)
}

const AddStudentModal = ({
  requiredIdType,
  handleSubmit,
  stepModal,
  onCancel,
  ...props
}) => {
  const { class: cls = {}, school: schoolId, district: districtId } = props
  const isPDClass = isPD(cls)

  const [studentList = [], studentsLoading] = useSchoolMembersByRoles(
    { id: schoolId, district: districtId },
    isPDClass ? ['admin', 'teacher'] : ['student']
  )
  const [schoolData, schoolLoading] = useFirestoreValues(schoolRef(schoolId))

  if (studentsLoading || schoolLoading) {
    return null
  }

  const filteredStudentList = studentList.filter(
    ({ id }) => !(cls.members || []).includes(id)
  )

  const isRosterSyncedSchool =
    schoolData && isRosterSynced(schoolData).providerDisplayName

  return requiredIdType ? (
    <Modal
      {...props}
      onCancel={onCancel}
      onOk={handleSubmit}
      title='Add a Student to Your Class'
    >
      <form onSubmit={handleSubmit}>
        <div style={{ margin: '24px 50px 0' }}>
          <Field
            {...props}
            name='student'
            placeholder='Find someone in your school...'
            component={SelectField}
            filterOption={filter}
            showSearch
          >
            {filteredStudentList
              .sort((a, b) => alphaCompare(a, b, 'name.given'))
              .map(s => (
                <Select.Option
                  label={getFullName(s) + getId(s, requiredIdType)}
                  value={s.id}
                  key={s.id}
                >
                  {getStudentNameWithId(s, requiredIdType)}
                </Select.Option>
              ))}
          </Field>
        </div>
        {!isPDClass && stepModal && !isRosterSyncedSchool && (
          <div style={{ paddingBottom: 20 }}>
            <Divider style={{ padding: '4px 100px 16px' }}>OR</Divider>
            <Row type='flex' gutter={16} justify='center'>
              {/* <Col>
                <Button
                  style={{ float: 'left' }}
                  className='secondary'
                  type='primary'
                  onClick={() => document.getElementById('csv').click()}
                  icon='upload'
                  ghost>
                  Upload Spreadsheet
                  <input
                    hidden
                    onChange={parseCsv}
                    id='csv'
                    name='csv'
                    accept='.csv, .xls, .xlsx'
                    type='file' />
                </Button>
              </Col> */}
              <Col>
                <Button
                  icon='user-add'
                  type='primary'
                  className='secondary'
                  ghost
                  onClick={stepModal.next}
                >
                  Create New Student
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </form>
    </Modal>
  ) : null
}

AddStudentModal.propTypes = {}

export default formModal(formInfo)(AddStudentModal)
export { AddStudentModal }

function cast(values, props) {
  return {
    class: props.class.id,
    student: trimValues(values).student
  }
}

function filter(inputVal, { props: { label = '' } }) {
  return label.toLowerCase().indexOf(inputVal.toLowerCase()) > -1
}
