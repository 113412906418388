import { compose, withHandlers } from 'recompose'
import { login } from 'shared/ducks/user'
import { connect } from 'react-redux'
import { Modal, Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import './SignInModal.less'

const btnProps = {
  size: 'large',
  type: 'primary',
  style: { margin: '15px auto', width: '65%', height: 50, display: 'block' }
}

const enhancer = compose(
  connect(
    null,
    { login }
  ),
  withHandlers({
    login: props => service => props.login(service)
  })
)

const SignInModal = ({ login, ...rest }) => {
  return (
    <Modal
      title='Sign in to 9 Dots'
      bodyStyle={{ padding: '45px 0 50px' }}
      footer={''}
      {...rest}
      visible>
      <Button {...btnProps} onClick={() => login('clever')} className='clever'>
        Sign in with Clever
      </Button>
      <Button {...btnProps} onClick={() => login('google')} className='google'>
        Sign in with Google
      </Button>
      <Button
        {...btnProps}
        onClick={() => login('classLink')}
        className='classLink'>
        Sign in with ClassLink
      </Button>
    </Modal>
  )
}

SignInModal.propTypes = {}

export default enhancer(SignInModal)
