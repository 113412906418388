import { getValidationErrors, getFormDefaults } from 'utils'
import { isAdmin, trimValues, isNinedotsRole } from 'shared/utils'
import { firestoreConnect } from 'react-redux-firebase'
import { createAnalyticsThunk } from 'shared/analytics'
import { helpEvents } from 'analytics/help'
import { compose, withHandlers } from 'recompose'
import waitFor from 'shared/components/waitFor'
import { rpc, setUrl } from 'shared/actions'
import formModal from 'components/formModal'
import { config } from 'configureStore'
import { connect } from 'react-redux'
import { message } from 'antd'
import schema from 'schema'

const profileDetailEnhancer = compose(
  connect(({ firebase }) => ({ profile: firebase.profile })),
  firestoreConnect(({ profile }) => [
    {
      collection: 'classes',
      where: [`teachers`, 'array-contains', profile.id],
      storeAs: `${profile.id}-classes`
    }
  ]),
  connect((state, { profile }) => ({
    classes: state.firestore.ordered[`${profile.id}-classes`]
  })),
  formModal({
    displayName: 'onboarding',
    mapPropsToValues: ({ profile }) => ({
      name: profile.name || { given: undefined, family: undefined },
      displayName: profile.displayName,
      ...(profile.role && { role: profile.role })
    }),
    handleSubmit: async (values, { props, setErrors }) => {
      const { rpc, setLoading, setUrl, profile, classes } = props
      const isTeacherInClass = classes.length > 0
      setLoading(true)
      try {
        await rpc('user.signUp', cast(values, props))
        if (isAdmin(profile)) {
          window.location.href = config.adminUrl
        } else if (isNinedotsRole(profile.role) || isTeacherInClass) {
          setUrl('/onboarding/guide')
        } else {
          setUrl('/onboarding/class')
        }
      } catch (e) {
        setLoading(false)
        if (e === 'school_not_found') {
          return setErrors({ school: 'School code not found.' })
        } else if (e.errorDetails) {
          return setErrors(getValidationErrors(e))
        }
        message.error('Oops, something went wrong. Please try again.')
      }
    },
    ...getFormDefaults(schema.user.signUp, cast)
  })
)

function cast (values, { profile, uid }) {
  return {
    ...trimValues(values),
    email: profile.email,
    teacher: uid
  }
}

const classOnboardingEnhancer = compose(
  withHandlers({
    close: ({ setUrl }) => async msg => {
      setUrl('/onboarding/guide')
    }
  }),
  waitFor(['school'])
)

const onboardingGuideEnhancer = compose(
  withHandlers({
    close: ({ setUrl }) => async msg => {
      setUrl('/')
    }
  }),
  connect(null, {
    trackTeacherGuideView: createAnalyticsThunk(helpEvents.teacherGuideView)
  })
)

export {
  profileDetailEnhancer,
  classOnboardingEnhancer,
  onboardingGuideEnhancer
}
export default compose(
  connect(
    ({
      firebase: {
        auth: { uid },
        profile
      },
      firestore: {
        ordered: { schools = [] }
      }
    }) => ({
      schools: schools.map(school => ({
        label: school.displayName,
        value: school.id
      })),
      school: (profile.schools || [])[0],
      uid,
      profile
    }),
    { rpc, setUrl }
  )
)
