import { Icon, Avatar, Row, Col } from 'antd'
import vars from 'shared/theme/vars/vars'
import UserMenu from '../UserMenu'
import React from 'react'

export default ({
  isTeacher,
  displayName,
  isCurriculumDeveloper,
  isStudent
}) => (
  <UserMenu
    isTeacher={isTeacher}
    isStudent={isStudent}
    isCurriculumDeveloper={isCurriculumDeveloper}
    overlayStyle={{ margin: '0 10px' }}
    button={
      <Row
        type='flex'
        align='middle'
        style={{
          height: vars['@layout-header-height'] + 'px',
          padding: '0 20px'
        }}>
        <Col>
          <Avatar icon='user' />
        </Col>
        <Col className='flex-grow'>{displayName}</Col>
        {isStudent ? null : (
          <Col>
            <Icon type='caret-down' style={{ fontSize: 9 }} />
          </Col>
        )}
      </Row>
    } />
)
