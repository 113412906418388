import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { setUrl } from 'shared/actions'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd'
import React from 'react'
import './withOAuthError.less'

const error = Modal.error

export default compose(
  withRouter,
  connect(
    null,
    { setUrl }
  ),
  lifecycle({
    componentDidMount () {
      const { route } = this.props.match.params

      switch (route) {
        case 'noPermission':
          return error({
            title: 'Permission Denied',
            content:
              'Please contact your school or district administrator for more information.',
            onOk: () => this.props.setUrl('/')
          })
        case 'pendingApproval':
          error({
            title: 'No Account',
            content: <NoAccountContent />,
            onOk: () => this.props.setUrl('/')
          })
      }
    }
  })
)

const NoAccountContent = () => (
  <span>
    This account does not exist. To create an account contact us at:
    <br />
    <br />
    <a href='mailto:help@9dots.org?subject=New Account Request'>
      help@9dots.org
    </a>
  </span>
)
