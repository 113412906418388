/**
 * @function getCompletedPercent
 * @description computes percentage of students that have completed
 * specified lesson
 * @param {Array} studentIds
 * @param {Number} totalCompleted lesson completion total
 * @returns {Number} lesson completion percentage
 */
export default function getCompletedPercent (studentIds = [], totalCompleted) {
  return (totalCompleted / studentIds.length) * 100
}
