import React, { createContext, useContext } from 'react'

export const BugReportContext = createContext()

const BugReportProvider = ({ children }) => {
  const showCollectorAction = () => 
    window.open(
      'https://form.asana.com/?hash=ed83ba68e46fe33383994fdc2405f0de20e51130e8b7bafa71a6e16a78521e8a&id=1154517678502709', 
      '_blank'
    )

  return (
    <BugReportContext.Provider value={{ showCollectorAction }}>
      {children}
    </BugReportContext.Provider>
  )
}

export const useBugReportProvider = () => {
  const reportContextData = useContext(BugReportContext)
  if (!reportContextData) {
    throw new Error(
      'useBugReportProvider must be used within a BugReportProvider'
    )
  }
  const { showCollectorAction } = reportContextData
  return showCollectorAction
}

export default BugReportProvider
