import { Modal, Icon, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import './CookiesModal.less'

const CookiesModal = props => {
  return (
    <Modal
      {...props}
      title='Cookies Are Disabled'
      className='cookies-modal'
      closable={false}
      maskClosable={false}
      onCancel={() => {}}
      centered={true}
      footer={null}>
      <Row type='flex' justify='center'>
        <Icon
          style={{ fontSize: 30, color: 'red' }}
          type='exclamation-circle' />
        <p className='content'>
          {`Your browser's cookies are currently disabled. To use this site, you must enable cookies in your 
          Web browser settings. For help, click on the link below:`}
        </p>
        <a
          rel='noopener noreferrer'
          target='_blank'
          href='https://support.google.com/accounts/answer/61416'>
          How to enable cookies?
        </a>
      </Row>
    </Modal>
  )
}

CookiesModal.propTypes = {}

export default CookiesModal
