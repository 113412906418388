/**
 * @file A hook for displaying the help modal.
 * @author Julius Diaz Panoriñgan
 */
import helpAnalyticsTracker from 'analytics/help'
import React from 'react'
import { useModal } from 'react-modal-hook'
import HelpModal from './HelpModal'

/**
 * @function useHelpModal
 * @exports
 * @param {string} trackerSource where this hook was called from, to use in analytics tracking
 * @param {boolean} [showResources=true] whether to show teacher resources related information
 * @returns {Array.<Function>} [showHelpModal, hideHelpModal]: functions to show/hide the helpModal
 */
export default (trackerSource, showResources = true) => {
  const analyticsTracker = helpAnalyticsTracker(trackerSource)

  const [showHelpModal, hideHelpModal] = useModal(() => (
    <HelpModal onCancel={hideHelpModal} showResources={showResources} />
  ))

  const showAndTrack = () => {
    if (trackerSource) {
      analyticsTracker.helpModalOpen()
    }
    showHelpModal()
  }

  return [showAndTrack, hideHelpModal]
}
