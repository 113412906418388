/**
 * @file classViews.js
 * @author Julius Diaz Panoriñgan
 * @author Giovanni Bonilla
 * File exports an object containing strings & objects referring to class
 * view related actions for passing into the 'useAnalyticsTracker' hook for
 * analytics tracking. File also exports a function that generates the
 * set of actions that trigger redux-segment middleware for class view
 * related analytics.
 *
 */

import { useAnalyticsTracker } from 'shared/analytics'

const classViewsAnalytics = {
  progressView: 'VIEWED_CLASS_PROGRESS', // tracks view of student Progress view (currently via 'Progress' in sidebar)
  insightsView: 'VIEWED_CLASS_INSIGHTS', // tracks view of student Insights view (currently via 'Insights' in sidebar)
  studentSortClick: {
    // tracks click on student sort dropdown
    type: 'CLICKED_STUDENT_SORT',
    createPropertiesFn: null
  },
  studentSortSelect: {
    // tracks update of student sort dropdown
    type: 'SELECTED_STUDENT_SORT',
    createPropertiesFn: (oldSortType, newSortType) => ({
      oldSortType,
      newSortType
    })
  }
}

export const classViewsEvents = Object.keys(classViewsAnalytics).reduce(
  (acc, curr) => {
    const event = classViewsAnalytics[curr]
    return {
      ...acc,
      [curr]: typeof event === 'object' ? event.type : event
    }
  },
  {}
)

export default defaultSource =>
  useAnalyticsTracker(classViewsAnalytics, defaultSource)
