import PrintPasswords from 'app/ClassWrapper/Class/TeacherClass/PrintPasswords'
import ClassLinkModal from 'app/ClassWrapper/Class/TeacherClass/ClassLinkModal'
import CreateStudentModal from 'modals/CreateStudentModal'
import TimeoutModal from 'shared/components/TimeoutModal'
import ReauthModal from 'shared/components/ReauthModal'
import modalContainer from 'components/modalContainer'
import ClassModal from 'app/ClassWrapper/ClassModal'
import StudentCsvUpload from 'app/StudentCsvUpload'
import LoginModal from 'app/LoginModal/LoginModal'
import SignInModal from 'components/SignInModal'
import StudentModal from 'modals/StudentModal'
import CookiesModal from 'app/CookiesModal'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import './Modals.less'

const Modals = props => {
  return (
    <div>
      <Modal {...props} component={TimeoutModal} name='timeoutModal' />
      <Modal {...props} component={ClassModal} name='classModal' />
      <Modal {...props} component={CreateStudentModal} name='editUser' edit />
      <Modal {...props} component={LoginModal} name='loginModal' />
      <Modal {...props} component={SignInModal} name='signInModal' />
      <Modal {...props} component={StudentModal} name='createStudent' />
      <Modal {...props} component={PrintPasswords} name='printPasswords' />
      <Modal {...props} component={ClassLinkModal} name='classLinkModal' />
      <Modal {...props} component={StudentCsvUpload} name='studentCsvModal' />
      <Modal {...props} component={ReauthModal} name='reauthModal' />
      <Modal {...props} component={CookiesModal} name='cookiesModal' />
    </div>
  )
}

Modals.propTypes = {
  modal: PropTypes.object.isRequired
}

export default modalContainer(Modals)
export { Modals }

const Modal = props => {
  const { modal, component: Component, name, ...rest } = props
  const isVisible = modal.isVisible(name)
  useEffect(() => {
    const { classList } = document.body
    const classes = ['modal-open', `modal-${name}`]
    classes.forEach(cls => {
      isVisible ? classList.add(cls) : classList.remove(cls)
    })
  }, [isVisible])
  return (
    isVisible && (
      <Component
        {...rest}
        visible
        onCancel={modal.hideModal(name)}
        onOk={modal.hideModal(name)}
        {...modal.getProps(name)} />
    )
  )
}
