import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import createStore, { rrfbConfig } from 'configureStore'
import BugReportProvider from 'providers/BugReportProvider'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import Root from 'app/Root'
import React from 'react'
import './index.less'

const store = createStore()
const config = rrfbConfig(store)
// if (process.env.NODE_ENV !== 'productison') {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React, { include: [/School/] })
// }

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...config}>
      <BugReportProvider>
        <Root />
      </BugReportProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
)
