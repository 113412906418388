import { schoolsByDistrict } from 'shared/firestore/refs'
import { Modal, Form, Row, Col, Button } from 'antd'
import Field, { TextField } from 'components/Field'
import ClassModal from '../ClassWrapper/ClassModal'
import useFirestore from 'shared/hooks/firestore'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import enhancer, {
  profileDetailEnhancer,
  classOnboardingEnhancer,
  onboardingGuideEnhancer
} from './enhancer'

import './Onboarding.less'

const Onboarding = props => {
  const [qSnap, loading] = useFirestore(
    schoolsByDistrict(props.profile.district)
  )
  if (loading) return <span />
  const schools = qSnap.docs.map(doc => doc.data())
  const propsWithSchools = { ...props, schools }
  return (
    <Switch>
      <Route
        path='/onboarding'
        exact
        render={routeProps => <ProfileDetails {...propsWithSchools} />} />
      <Route
        path='/onboarding/class'
        exact
        render={routeProps => <ClassOnboarding {...propsWithSchools} />} />
      <Route
        path='/onboarding/guide'
        exact
        render={routeProps => <OnboardingGuide {...propsWithSchools} />} />
    </Switch>
  )
}

const ClassOnboarding = classOnboardingEnhancer(props => {
  return (
    <ClassModal onOk={props.close} onCancel={props.close} visible {...props} />
  )
})

const itemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
}

const ProfileDetails = profileDetailEnhancer(props => {
  return (
    <Modal
      title='Complete Your Profile!'
      className='onboaring-modal'
      visible
      onOk={props.handleSubmit}
      confirmLoading={props.confirmLoading}
      maskClosable={false}
      footer={
        <Button
          loading={props.confirmLoading}
          onClick={props.handleSubmit}
          type='primary'>
          Next
        </Button>
      }
      closable={false}>
      <form onSubmit={props.handleSubmit}>
        <Form.Item className='form-row' {...itemLayout} label='Full Name'>
          <Row gutter={8}>
            <Col span={12}>
              <Field
                {...props}
                name='name.given'
                component={TextField}
                placeholder='First' />
            </Col>
            <Col span={12}>
              <Field
                {...props}
                name='name.family'
                component={TextField}
                placeholder='Last' />
            </Col>
          </Row>
        </Form.Item>
        <Field
          {...props}
          label='Displayed Name'
          itemProps={itemLayout}
          name='displayName'
          component={TextField}
          placeholder='Ms. Lastname' />
        <button type='submit' style={{ display: 'none' }} />
      </form>
    </Modal>
  )
})

const OnboardingGuide = onboardingGuideEnhancer(props => {
  return (
    <Modal
      {...props}
      visible={true}
      title='Learn More'
      footer={null}
      closable={false}
      maskClosable={false}
      className='onboarding-guide-modal'>
      <p className='content'>
        Want to learn more before getting started? View our teacher guide for
        how to use the 9 Dots Platform{' '}
      </p>
      <a
        href='https://docs.google.com/document/d/1zXYhxutGJDtjmMMSNx_llaYOisNon2Sumzfs1uv6Sa4/edit'
        rel='noopener noreferrer'
        target='_blank'>
        <Button
          type='primary'
          className='secondary'
          onClick={() => {
            props.trackTeacherGuideView('Onboarding')
            props.close()
          }}>
          Yes please, take me to the teacher guide!{' '}
        </Button>
      </a>
      <Button
        type='primary'
        onClick={props.close}>{`No thanks, let's get started now!`}</Button>
    </Modal>
  )
})

Onboarding.propTypes = {
  handleSubmit: PropTypes.func,
  uid: PropTypes.string
}

export default enhancer(Onboarding)
export { Onboarding }
