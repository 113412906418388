import { compose, withPropsOnChange } from 'recompose'
import { validate, getValidationErrors } from 'utils'
import { errorToMessage } from 'shared/utils/errors'
import formModal from 'components/formModal'
import { trimValues } from 'shared/utils'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import { message } from 'antd'
import schema from 'schema'

export default compose(
  connect(
    ({ firebase: { profile } }, { user = {} }) => ({
      isMe: profile.id === user.id
    }),
    { rpc }
  ),
  // attempt to minimize recalculation of dynamic form validator
  withPropsOnChange(['edit', 'requiredIdType'], ({ edit, requiredIdType }) => {
    let validator
    if (edit) {
      validator = schema.user.editUser
    } else {
      switch (requiredIdType) {
        case 'stateId':
          validator = schema.user.createStudentWithStateId
          break
        case 'email':
          validator = schema.user.createStudentWithEmail
          break
        default:
          validator = schema.user.createStudent
      }
    }
    return {
      validateFxn: validate(validator, cast)
    }
  }),
  formModal({
    displayName: 'createStudent',
    mapPropsToValues: ({ user = {} }) => ({
      name: { given: '', family: '' },
      stateId: '',
      email: '',
      ...user
    }),
    handleSubmit: async (values, { props, setErrors }) => {
      const { setLoading, edit, rpc, class: cls } = props
      const fn = edit ? 'user.editUser' : 'user.createStudent'
      setLoading(true)

      try {
        const res = await rpc(fn, cast(values, props))
        if (edit) {
          return props.onOk('Success! Edited user.')
        } else {
          await rpc('class.addStudent', {
            class: cls.id,
            student: res.uid
          })
          return props.onOk('Success! Added student.')
        }
      } catch (e) {
        setLoading(false)
        if (e.errorDetails) {
          return setErrors(getValidationErrors(e))
        }
        message.error(
          errorToMessage(e.error) ||
            'Oops, something went wrong. Please try again.'
        )
      }
    },
    validateOnChange: true,
    validateOnBlur: false,
    // see withPropsOnChange HOC above for validateFxn prop
    validate: (values, props) => {
      const { validateFxn } = props
      return validateFxn(values, props)
    }
  })
)

function cast(values, props) {
  const trimmed = trimValues(values)
  const { class: cls, school, edit, isMe } = props

  return edit
    ? {
        ...(isMe && { displayName: trimmed.displayName }),
        name: trimmed.name,
        id: trimmed.id
      }
    : {
        ...trimmed,
        stateId: trimmed.stateId || undefined,
        email: trimmed.email || undefined,
        district: cls.district,
        grade: cls.grade,
        school
      }
}
