import useHelpModal from 'modals/HelpModal/useHelpModal'
import modalContainer from 'components/modalContainer'
import { Layout, Button, Icon, Row, Col } from 'antd'
import React from 'react'
import './HomeHeader.less'

const HomeHeader = ({ allowSignIn, modal }) => {
  const [showHelpModal] = useHelpModal('HomeHeader', false) // do no show internal resource related links

  return (
    <Layout.Header className='dark'>
      <Row type='flex'>
        <Col className='flex-grow'>
          <h1 style={{ color: 'white' }}>
            <b style={{ letterSpacing: '1px' }}>9 DOTS</b>
          </h1>
        </Col>
        <Col>
          <span
            style={{
              color: 'white',
              marginRight: 15,
              fontSize: 12,
              cursor: 'pointer'
            }}
            onClick={showHelpModal}
          >
            <Icon
              type='question-circle'
              style={{ fontSize: 14, marginRight: 3 }}
            />
            Help
          </span>
          <a
            style={{ color: 'white', marginRight: 15, fontSize: 12 }}
            href='https://www.9dots.org/privacy'
            rel='noopener noreferrer'
            target='_blank'
          >
            Privacy Policy
          </a>
          <a
            style={{ color: 'white', marginRight: 30, fontSize: 12 }}
            href='https://www.9dots.org/terms-of-use'
            rel='noopener noreferrer'
            target='_blank'
          >
            Terms of Use
          </a>
        </Col>
        <Col>
          {!!allowSignIn && (
            <Button
              onClick={modal.showModal('signInModal')}
              type='primary'
              className='secondary'
            >
              <Icon type='login' />
              SIGN IN
            </Button>
          )}
        </Col>
      </Row>
    </Layout.Header>
  )
}

HomeHeader.propTypes = {}

export default modalContainer(HomeHeader)
export { HomeHeader }
