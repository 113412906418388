/**
 * @function findLesson
 * @description finds specified lesson from list of modules
 * @param {String} lessonId
 * @param {String} moduleId
 * @param {Array} modules
 * @returns {Object}
 */
export default function findLesson (lessonId, moduleId, modules = []) {
  const mod =
    modules.find(({ module: { ref } }) => {
      return moduleId === ref
    }) || {}
  const lesson = (mod.lessons || []).find(({ id }) => lessonId === id)
  return { ...lesson, moduleName: mod.displayName }
}
