import padStart from 'lodash/padStart'

export default function durationToString (dur = 0) {
  var num = Math.floor(parseInt(dur, 10) / 1000)
  var hours = padStart(Math.floor(num / 3600), 2, '0')
  var minutes = padStart(Math.floor((num - hours * 3600) / 60), 2, '0')
  var seconds = padStart(num - hours * 3600 - minutes * 60, 2, '0')

  return hours + ':' + minutes + ':' + seconds
}
