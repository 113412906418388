/**
 * @function getCompletedCount
 * @description computes total number of students that have completed
 * specified lesson
 * @param {Array} studentIds
 * @param {Object} progressData lesson progress data
 * @returns {Number} lesson completion total
 */
export default function getCompletedCount (studentIds = [], progressData = {}) {
  return studentIds.reduce((totalCompleted, student) => {
    if (progressData[student]) {
      totalCompleted += progressData[student].completed ? 1 : 0
    }
    return totalCompleted
  }, 0)
}
