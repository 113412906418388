import { Dropdown, Menu, Icon, Button } from 'antd'
import helpAnalyticsTracker from 'analytics/help'
import useFirestore from 'shared/hooks/firestore'
import { userRef } from 'shared/firestore/refs'
import HelpModal from 'modals/HelpModal'
import { useModal } from 'react-modal-hook'
import React, { useCallback } from 'react'
import enhancer from './enhancer'
import './UserMenu.less'

const UserMenu = ({
  overlayStyle = {},
  isTeacher,
  isStudent,
  button,
  logout,
  modal,
  uid
}) => {
  const trackerSource = 'UserMenu'
  const analyticsTracker = helpAnalyticsTracker(trackerSource)
  const [snap, loading] = useFirestore(userRef(uid))
  const user = loading ? {} : snap.data()
  const [showHelpModal, hideHelpModal] = useModal(() => (
    <HelpModal onCancel={hideHelpModal} />
  ))

  const menuClick = useCallback(
    event => {
      switch (event.key) {
        case 'logout':
          return logout(user)
        case 'settings':
          return modal.showModal(
            { name: 'editUser', user: { id: uid, ...user } },
            null
          )
        case 'contact':
          analyticsTracker.helpModalOpen()
          showHelpModal()
          break
        default:
          break
      }
    },
    [user]
  )

  const overlay = (
    <Menu onClick={menuClick} style={{ minWidth: 150, ...overlayStyle }}>
      {isTeacher && [
        <Menu.Item key='settings'>
          <Icon type='setting' style={{ marginRight: 10 }} />
          Settings
        </Menu.Item>,
        <Menu.Item key='contact'>
          <Icon type='question-circle' style={{ marginRight: 10 }} />
          Help / Contact Us
        </Menu.Item>
      ]}
      <Menu.Item key='logout'>
        <Icon type='logout' style={{ marginRight: 10 }} />
        Log Out
      </Menu.Item>
    </Menu>
  )
  return (
    <span className='user-menu'>
      {isStudent ? (
        button
      ) : (
        <Dropdown overlay={overlay} trigger={['click']}>
          {button || (
            <Button icon='ellipsis' ghost className='rot90' shape='circle' />
          )}
        </Dropdown>
      )}
    </span>
  )
}

export default enhancer(UserMenu)
export { UserMenu }
