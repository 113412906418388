import React, { useCallback, useEffect, useState } from 'react'
import { getItem } from 'redux-effects-localstorage'
import { Modal, Button, Row, Col } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './ReauthModal.less'

const ReauthModal = props => {
  const [redirect, setRedirect] = useState()
  const redirectToLogin = useCallback(() => {
    return redirect ? window.location.assign(redirect) : props.onClickLogin()
  }, [redirect])
  useEffect(() => {
    props.getItem('studentLogoutRedirect').then(value => setRedirect(value))
  }, [])
  return (
    <Modal
      title='SESSION EXPIRED'
      className='timeout-modal'
      footer={''}
      {...props}
      visible>
      <h3>
        For your security, your session has timed out. Please sign in again to
        continue.
      </h3>
      <Row type='flex' gutter={16} justify='center'>
        <Col>
          <Button type='primary' onClick={redirectToLogin}>
            Sign In
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

ReauthModal.propTypes = {}

export default connect(
  null,
  { getItem }
)(ReauthModal)
