import withOAuthError from 'components/withOAuthError'
import { Route, Switch, Redirect } from 'react-router-dom'
import Loading from 'shared/components/Loading'
import AuthHandler from 'app/AuthHandler'
import HomeLayout from 'app/HomeLayout'
import Modals from 'components/Modals'
import Onboarding from '../Onboarding'
import Loadable from 'react-loadable'
import AppLayout from '../AppLayout'
import Terms from '../Terms'
import React from 'react'
import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticated,
  userHasTeacherAccess,
  userIsAuthenticated,
  userIsOnboarded,
  agreedToTerms,
  splashRedir
} from '../../auth'

import './Router.less'

// const AsyncStudentProfile = Loadable({
//   loader: () => import('shared/components/StudentProfile'),
//   loading: Loading
// })

function MyLoadable(opts) {
  return Loadable(Object.assign({
    loading: Loading
  }, opts))
}

const AsyncHome = MyLoadable({ loader: () => import('app/Home') })
const AsyncClassWrapper = MyLoadable({ loader: () => import('app/ClassWrapper') })
const AsyncLibrary = MyLoadable({ loader: () => import('app/Library') })
const AsyncModuleEditor = MyLoadable({ loader: () => import('app/ModuleEditor') })
const AsyncModuleView = MyLoadable({ loader: () => import('app/ModuleView') })
const AsyncSchoolLogin = MyLoadable({ loader: () => import('app/SchoolLogin') })
const AsyncPrivacy = MyLoadable({ loader: () => import('app/Privacy') })
const AsyncPreview = MyLoadable({ loader: () => import('app/PreviewLessonView') })
const AsyncCourses = MyLoadable({ loader: () => import('app/Courses') })
const AsyncLandingPage = MyLoadable({ loader: () => import('components/HomeBanner') })
const AsyncResources = MyLoadable({ loader: () => import('app/Resources') })

const OAuthError = withOAuthError(AsyncCourses)

const App = props => {
  const {
    match: {
      params: { route }
    }
  } = props
  return (
    <div>
      <Switch>
        <Route
          exact
          path='/module/:moduleId/edit'
          component={AsyncModuleEditor}
        />
        <AppLayout showSidebar={route !== 'school'} {...props}>
          <Switch>
            <Route
              path='/class/:classId?/:subview?'
              component={AsyncClassWrapper}
            />
            <Route exact path='/library' component={AsyncLibrary} />
            <Route
              exact
              path='/courses/:courseId?'
              render={props => (
                <AsyncCourses {...props} isAuthenticated={true} />
              )}
            />
            {/* <Route
              exact
              path='/student/:studentId'
              component={AsyncStudentProfile} /> */}
            <Route
              exact
              path='/courses/modules/:moduleId'
              render={props => (
                <AsyncModuleView {...props} trackerSource='Course Catalog' />
              )}
            />
            <Route
              exact
              path='/library/modules/:moduleId'
              component={AsyncModuleView}
            />
            <Route
              path='/school/:schoolId/:classId?'
              render={props => <AsyncSchoolLogin {...props} />}
              exact
            />
            <Route
              path='/resources'
              component={AsyncResources}
            />
            <Redirect
              from='/teacher-guide'
              to='/resources'
            />
            <Route exact path='/' component={AsyncHome} />
            <Redirect to='/' />
          </Switch>
        </AppLayout>
      </Switch>
    </div>
  )
}

const HomeRoutes = ({
  match: {
    params: { route }
  }
}) => (
  <HomeLayout allowSignIn={route !== 'school'}>
    <Switch>
      <Redirect exact path='/' to='/home' />
      <Redirect exact path='/courses/modules/:moduleId?' to='/' />
      <Redirect exact path='/courses/:courseId?' to='/' />
      <Route
        exact
        path='/school/:schoolId/:classId?'
        component={AsyncSchoolLogin}
      />
      <Route exact path='/home' render={() => <AsyncLandingPage />} />
      <Route
        exact
        path='/:route(pendingApproval|noPermission)'
        render={props => <OAuthError {...props} isAuthenticated={false} />}
      />
    </Switch>
  </HomeLayout>
)

const routes = (
  <span>
    <Switch>
      <Route path='/authhandler' component={AuthHandler} />
      <Route exact path='/privacy' component={AsyncPrivacy} />
      <Route path='/terms' component={userIsAuthenticatedRedir(Terms)} />
      <Route
        exact
        path='/module/:moduleId/lesson/:lessonId/:activityNum/preview'
        render={routeProps => <AsyncPreview {...routeProps} teacherView />}
      />
      <Route
        path='/onboarding'
        component={userIsAuthenticatedRedir(Onboarding)}
      />
      <Route path='/'>
        <Switch>
          <Route
            path='/:route(home|courses|packs|school|pendingApproval|noPermission|login)'
            component={userIsNotAuthenticated(HomeRoutes)}
          />
          <Route
            path='/'
            component={userIsNotAuthenticated(splashRedir(HomeRoutes))}
          />
        </Switch>
        <Route
          path='/:route?'
          component={userIsAuthenticated(
            agreedToTerms(userIsOnboarded(userHasTeacherAccess(App)))
          )}
        />
      </Route>
    </Switch>
    <Modals />
  </span>
)

export { routes }
