import CreateStudentModal from 'modals/CreateStudentModal'
import { firestoreConnect } from 'react-redux-firebase'
import AddStudentModal from 'modals/AddStudentModal'
import StepModal from 'components/StepModal'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import React from 'react'

import './StudentModal.less'

const StudentModal = props => {
  return <StepModal {...props} modals={[AddStudentModal, CreateStudentModal]} />
}

StudentModal.propTypes = {}

/** @todo refactor to separate enhancer?! Or using hooks */
// inject required id type for class's district
export default compose(
  firestoreConnect(({ district, class: cls }) => [
    {
      collection: 'districts',
      doc: district,
      storeAs: `class-${cls.id}-district`
    }
  ]),
  connect(({ firestore: { data } }, { class: cls }) => {
    const districtInfo = data[`class-${cls.id}-district`]
    return {
      requiredIdType: districtInfo && districtInfo.uniqueIdField
    }
  })
)(StudentModal)

/** @todo create hook for this modal and pull from antiquated modalContainer system */
