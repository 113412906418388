import modalContainer from 'components/modalContainer'
import { Modal, Button, Row, Col } from 'antd'
import React from 'react'

import './LoginModal.less'

const LoginModal = props => {
  const hideSelfHandler = props.modal.hideModal('loginModal')
  const { onOk = hideSelfHandler, onCancel = hideSelfHandler, ...rest } = props

  return (
    <Modal
      title='SIGN IN'
      className='login-modal'
      footer={''}
      onOk={onOk}
      onCancel={onCancel}
      {...rest}
      visible>
      <h3>You need to sign in first.</h3>
      <Row type='flex' gutter={16} justify='center'>
        <Col>
          <Button icon='close' onClick={() => onCancel(null)}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            icon='login'
            className='secondary'
            type='primary'
            onClick={e => {
              props.modal.showModal('signInModal')(e)
              onCancel(null)
            }}>
            Sign In
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

LoginModal.propTypes = {}

export default modalContainer(LoginModal)
export { LoginModal }
