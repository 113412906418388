import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import Loading from 'shared/components/Loading'
import getConfig from 'shared/config/getConfig'
import { version } from './app/Terms'

const isTeacher = profile =>
  (profile.schools || []).length > 0 ||
  profile.role === 'ninedotsTeacher' ||
  profile.role === 'ninedotsAdmin'

const config = getConfig()

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => {
    return !state.firebase.profile.isEmpty
  },
  authenticatingSelector: state => {
    return !state.firebase.profile.isLoaded
  },
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'UserIsAuthenticated'
}

export const userIsAuthenticated = connectedAuthWrapper(
  userIsAuthenticatedDefaults
)


export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: Loading,
  redirectPath: '/home'
})

export const userIsOnboarded = connectedRouterRedirect({
  redirectPath: '/onboarding',
  allowRedirectBack: false,
  authenticatingSelector: state => !state.firebase.profile.isLoaded,
  AuthenticatingComponent: Loading,
  authenticatedSelector: ({ firebase: { profile } }) => isOnboarded(profile),
  wrapperDisplayName: 'Onboarded'
})

export const userHasTeacherAccess = connectedRouterRedirect({
  redirectPath: ({ firebase: { profile } }) => {
    return isTeacher(profile) ? '/' : (window.location = config.adminUrl)
  },
  allowRedirectBack: false,
  authenticatingSelector: state => !state.firebase.profile.isLoaded,
  AuthenticatingComponent: Loading,
  authenticatedSelector: ({ firebase: { profile = {} } }) =>
    isTeacher(profile) || !isOnboarded(profile),
  wrapperDisplayName: 'UserHasTeacherAccess'
})

export const agreedToTerms = connectedRouterRedirect({
  redirectPath: '/terms',
  allowRedirectBack: false,
  authenticatingSelector: state => !state.firebase.profile.isLoaded,
  AuthenticatingComponent: Loading,
  authenticatedSelector: ({ firebase: { profile } }) =>
    profile &&
    (profile.role === 'student' ||
      (parseInt(profile.termsVersion, 10) || 0) >= version),
  wrapperDisplayName: 'AgreedToTerms'
})

export const userHasNoSchool = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: state =>
    state.firebase.profile &&
    (state.firebase.profile.schools || []).length === 0,
  wrapperDisplayName: 'UserHasNoSchool'
})

export const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  AuthenticatingComponent: Loading,
  authenticatedSelector: state => {
    return state.firebase.profile.isLoaded && state.firebase.profile.isEmpty
  },
  authenticatingSelector: state => !state.firebase.profile.isLoaded,
  wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsNotAuthenticated = connectedAuthWrapper(
  userIsNotAuthenticatedDefaults
)

export const userIsNotAuthenticatedRedirect = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  redirectPath: (state, ownProps) => '/login',
  allowRedirectBack: false
})

const splashDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  AuthenticatingComponent: Loading,
  authenticatedSelector: state => {
    return state.firebase.profile.isLoaded && !state.firebase.profile.isEmpty
  },
  authenticatingSelector: state => !state.firebase.profile.isLoaded,
  wrapperDisplayName: 'SplashRedirect'
}

export const splashRedir = connectedRouterRedirect({
  ...splashDefaults,
  redirectPath: '/home',
  allowRedirectBack: false
})

const isOnboarded = (user = {}) => user.displayName && !user.invitePending
