import { compose, withHandlers, withProps } from 'recompose'
import { showModal, hideModal } from 'ducks/modals'
import { connect } from 'react-redux'
import curry from 'curry'

import './modalContainer.less'

export default compose(
  connect(
    ({ modal }) => ({ modal }),
    { showModal, hideModal }
  ),
  withHandlers({
    showModal: props =>
      curry((modal, e) => {
        if (typeof modal === 'string') {
          return props.showModal(modal)
        } else if (typeof modal === 'object') {
          const { name, authRequired, ...restProps } = modal
          if (!name) {
            throw new Error('Object must have a name property')
          }
          return props.showModal(name, restProps, authRequired)
        }
        throw new Error('First argument must be a string or an object')
      }),
    hideModal: props => curry((name, e) => props.hideModal(name)),
    getModalProps: props => name => props.modal[name] || {},
    isVisible: props => name => {
      return !!props.modal[name]
    }
  }),
  withProps(props => ({
    modal: {
      showModal: props.showModal,
      hideModal: props.hideModal,
      isVisible: props.isVisible,
      getProps: props.getModalProps
    }
  }))
)
