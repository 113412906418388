import usePermissionUpdate from 'shared/hooks/usePermissionUpdate'
import usePresenceToast from 'shared/hooks/usePresenceToast'
import trackPageViews from 'components/trackPageViews'
import React, { useEffect, useState } from 'react'
import { ModalProvider } from 'react-modal-hook'
import { useAnalytics } from 'shared/analytics'
import Loading from 'shared/components/Loading'
import { cookieLogin } from 'shared/ducks/user'
import { Router } from 'react-router-dom'
import { showModal } from 'ducks/modals'
import { history } from 'configureStore'
import { connect } from 'react-redux'
import { routes } from 'app/Router'
import PropTypes from 'prop-types'

const AppRouter = trackPageViews(Router)

const Root = props => {
  const [loggingIn, setLoggingIn] = useState(true)
  useEffect(() => {
    const cookiesDisabled = !navigator.cookieEnabled
    if (cookiesDisabled) props.showModal('cookiesModal')
  }, [])
  useEffect(() => {
    props
      .cookieLogin()
      .then(() => setLoggingIn(false))
      .catch(() => setLoggingIn(false))
  }, [])
  usePermissionUpdate(props.profile)
  usePresenceToast()
  const analyticsLoading = useAnalytics()
  return loggingIn || analyticsLoading ? (
    <Loading />
  ) : (
    <AppRouter history={history}>
      <ModalProvider>{routes}</ModalProvider>
    </AppRouter>
  )
}

Root.propTypes = {}

export default connect(
  ({ firebase: { profile } }) => ({
    profile
  }),
  { showModal, cookieLogin }
)(Root)
