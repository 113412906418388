import { Modal, Form, Row, Col, Icon } from 'antd'
import Field, { TextField } from 'components/Field'
import PropTypes from 'prop-types'
import enhancer from './enhancer'
import React from 'react'

import './CreateStudentModal.less'

const itemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
}

const CreateStudentModal = ({
  mask = true,
  stepModal,
  isMe,
  edit,
  user = {},
  requiredIdType,
  ...props
}) => {
  const { class: cls = {} } = props
  const { displayName, grade } = cls
  return requiredIdType || edit ? (
    <Modal
      {...props}
      mask={mask}
      visible
      destroyOnClose
      onCancel={props.close(props.onCancel)}
      onOk={props.handleSubmit}
      confirmLoading={props.confirmLoading}
      title={edit ? 'Edit User' : 'Add Student to ' + displayName}
    >
      <Form onSubmit={props.handleSubmit}>
        <Form.Item {...itemLayout} label='Full Name' className='form-row'>
          <Row gutter={8}>
            <Col span={12}>
              <Field
                {...props}
                name='name.given'
                placeholder='First'
                component={TextField}
              />
            </Col>
            <Col span={12}>
              <Field
                {...props}
                name='name.family'
                placeholder='Last'
                component={TextField}
              />
            </Col>
          </Row>
        </Form.Item>
        {isMe && (
          <Form.Item {...itemLayout} label='Display Name' className='form-row'>
            <Field
              {...props}
              name='displayName'
              placeholder='Displayed name'
              component={TextField}
            />
          </Form.Item>
        )}

        {// only show stateId field if required by district
        requiredIdType === 'stateId' && !edit && (
          <Field
            {...props}
            itemProps={itemLayout}
            label='State ID'
            name='stateId'
            placeholder='State ID'
            component={TextField}
          />
        )}

        {// only show email field if required by district
        // OR--Still require emails if this is a PD class
        (requiredIdType === 'email' || grade === 'ProfessionalDevelopment') &&
          !edit && (
            <Field
              {...props}
              itemProps={itemLayout}
              label='Email'
              name='email'
              placeholder='student@email.com'
              component={TextField}
            />
          )}
      </Form>
      {stepModal && (
        <div style={{ textAlign: 'center', fontSize: 12 }}>
          <a onClick={stepModal.next}>
            <Icon type='plus' />
            &ensp;Find an Existing Student
          </a>
        </div>
      )}
    </Modal>
  ) : null
}

CreateStudentModal.propTypes = {
  close: PropTypes.func.isRequired,
  class: PropTypes.object
}

export default enhancer(CreateStudentModal)
export { CreateStudentModal }
