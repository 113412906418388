import { signInWithCredential } from 'shared/ducks/user'
import URLSearchParams from 'url-search-params'
import Loading from 'shared/components/Loading'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import './AuthHandler.less'

class AuthHandler extends React.PureComponent {
  constructor (props) {
    super(props)
    const searchParams = new URLSearchParams(props.location.search)
    const token = searchParams.get('token')
    props.signInWithCredential(token)
  }
  render () {
    return <Loading />
  }
}

AuthHandler.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
  signInWithCredential: PropTypes.func.isRequired
}

export default connect(
  null,
  { signInWithCredential }
)(AuthHandler)

export { AuthHandler }
