import { Input } from 'antd'
import React from 'react'
import './ClassLink.less'

const ClassLink = ({ classData = {}, ...rest }) => {
  return (
    <div {...rest}>
      {classData.passwordType ? (
        <>
          <p>
            Bookmark the link below on your students’ computers. They can use
            this link to sign in by selecting their class. Print their passwords
            for them on the &quot;Students&quot; tab.
          </p>
          <Input
            readOnly
            onClick={e => e.target.select()}
            value={`${window.location.origin}/school/${classData.school}`} />
        </>
      ) : (
        <p>
          The login link is disabled until you select an option from the{' '}
          <b>Generate Passwords</b> dropdown. You can do this in the{' '}
          <b>Students</b> tab to left.
        </p>
      )}
    </div>
  )
}

ClassLink.propTypes = {}

export default ClassLink
