import { userDidLogin, logout } from 'shared/ducks/user/actions'
import { showModal, hideModal } from 'ducks/modals'
import { combineLatest, interval } from 'rxjs'
import { object } from 'rxfire/database'
import { map } from 'rxjs/operators'

const modalDuration = 60 * 1000

/**
 * @function verifySession
 * @description Gets expiration time from firebase realtime database and adjusts for server time offset
 * If the session expires in less than {modalDuration} open a modal to prompt the user to extend session. If
 * session has expired log user out and open a reauthModal. Unsubscribe from the observable on a local LOGOUT action.
 */
export default function verifySession ({ getState, dispatch }) {
  let expirationObs$ = null
  return next => action => {
    if (action.type === 'LOGOUT' && expirationObs$) {
      // unsubscribe from observable on logout
      expirationObs$.unsubscribe()
      expirationObs$ = null
    }

    if (action.type === userDidLogin.type && action.payload) {
      if (expirationObs$) {
        // It's possible for a student to login when another user is already
        // logged in via cookie. In this scenario, there's already an expiration
        // observer, and we should unsubscribe from it.
        expirationObs$.unsubscribe()
      }
      const db = window.firebase.database()
      const uid = action.payload
      // observable of session expiration timestamp
      const expires$ = object(db.ref(`sessions/${uid}/expires`)).pipe(
        map(({ snapshot }) => snapshot.val())
      )
      // observable of server timestamp offset
      const offset$ = object(db.ref(`.info/serverTimeOffset`)).pipe(
        map(({ snapshot }) => snapshot.val())
      )
      // interval at 1s
      const timer$ = interval(1000)
      expirationObs$ = combineLatest(expires$, offset$, timer$)
        .pipe(map(([expires = 0, offset = 0]) => expires - offset))
        .subscribe(expires => {
          if (!expires || expires < Date.now()) {
            if (getState().modal.timeoutModal) {
              dispatch(hideModal('timeoutModal'))
            }
            if (!getState().modal.reauthModal) {
              dispatch(
                showModal('reauthModal', {
                  onClickLogin: () => {
                    dispatch(hideModal('reauthModal'))
                    dispatch(showModal('signInModal'))
                  }
                })
              )
            }
            return dispatch(logout(getState().firebase.profile, true))
          } else if (
            expires - modalDuration < Date.now() &&
            !getState().modal.timeoutModal
          ) {
            return dispatch(showModal('timeoutModal', { expires }))
          } else if (
            expires - modalDuration > Date.now() &&
            getState().modal.timeoutModal
          ) {
            return dispatch(hideModal('timeoutModal'))
          }
        })
    }
    return next(action)
  }
}
