/** @todo merge into withLoadingAndFormik (or vice versa) */

import { compose, withHandlers } from 'recompose'
import { withFormik } from 'formik'
import addLoading from 'components/addLoading'
import './formModal.less'

const formModal = form =>
  compose(
    addLoading,
    withHandlers({
      close: ({ setLoading }) => handler => (...args) => {
        setLoading(false)
        handler(...args)
      }
    }),
    withFormik(form)
  )

export default formModal
