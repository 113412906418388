import modalContainer from 'components/modalContainer'
import { logout } from 'shared/ducks/user'
import { connect } from 'react-redux'
import { uid } from '../../selectors'
import { compose } from 'recompose'

export default compose(
  modalContainer,
  connect(
    state => ({
      uid: uid(state)
    }),
    { logout }
  )
)
