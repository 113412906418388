import { createStore, applyMiddleware } from 'redux'
import { createFirestoreInstance } from 'redux-firestore'
import createTracker from 'shared/analytics/middleware'
import localStorage from 'redux-effects-localstorage'
import getConfig from 'shared/config/getConfig'
import { createBrowserHistory } from 'history'
import createDebounce from 'redux-debounced'
import * as middlewares from 'middleware'
import rootReducer from './reducers'
import effects from 'redux-effects'
import api from 'shared/utils/api'
import thunk from 'redux-thunk'
import { Modal } from 'antd'
require('moment')
require('moment-duration-format')

const tracker = createTracker()

const history = createBrowserHistory()
const config = getConfig()

window.firebase.initializeApp(config)
window.firebase
  .auth()
  .setPersistence(window.firebase.auth.Auth.Persistence.NONE)
const firestore = window.firebase.firestore()
firestore.settings({ timestampsInSnapshots: true })

const script = document.createElement('script')
script.type = 'text/javascript'
script.src = 'https://apis.google.com/js/api.js'

document.getElementsByTagName('head')[0].appendChild(script)
const rrfbConfig = store => ({
  firebase: window.firebase,
  dispatch: store.dispatch,
  createFirestoreInstance,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
    updateProfileOnLogin: false,
    oneListenerPerPath: true,
    logListenerError: true,
    enableLogging: false,
    onAuthStateChanged: async (user, firebase, dispatch) => {
      if (user) {
        const { curriculumDeveloper = false } = await firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(snap => snap.data())
        if (curriculumDeveloper) {
          window.gapi.load('client', {
            callback: () => window.gapi.client.load(config.discoveryDocs[0])
          })
          api('auth/getAccessToken')
            .then(res => {
              window.gapi.client.setToken(res.tokens)
            })
            .catch(e => {
              if (e.error && e.error.errors['authUrl']) {
                Modal.error({
                  title: 'Google Auth Error',
                  content:
                    'We had trouble with your google credential. You will now be redirected to update your credentials.',
                  onOk: () => {
                    window.location = e.error.errors.authUrl.value
                  }
                })
              }
            })
        }
      }
    }
  }
})

export { history, config, firestore, rrfbConfig }
export default (initialState = {}) => {
  const windowLocalStorage = getLocalStorage()
  const middleware = [
    middlewares.authRequired,
    middlewares.verifySession,
    createDebounce(),
    thunk.withExtraArgument({ history }),
    effects
  ].concat(
    windowLocalStorage,
    process.env.NODE_ENV === 'production' ? tracker : []
  )
  const store = createStore(
    rootReducer,
    {}, // initial state
    applyMiddleware(...middleware)
  )
  return store
}

function getLocalStorage (arr) {
  try {
    if (window.localStorage !== 'undefined') {
      return localStorage(window.localStorage)
    }
  } catch (e) {
    if (navigator.cookiesEnabled) {
      Modal.warning({
        title: 'Local Storage Disabled',
        content: `Your browser's local storage is currently disabled. Local storage must be enabled to fully access all of our site's features`
      })
    }

    return []
  }
}
