import urlJoin from 'url-join'

export default (module, lesson, index = 0, redirect) => {
  return urlJoin(
    '/module',
    module || '',
    '/lesson',
    lesson || '',
    index.toString(),
    'preview',
    redirect ? '?redirect=' + redirect : ''
  )
}
