import { classesForUser } from 'shared/firestore/refs'
import HomeHeader from '../HomeLayout/HomeHeader'
import { formatIntercomUser } from 'shared/utils'
import useFirestore from 'shared/hooks/firestore'
import { Route, Switch } from 'react-router-dom'
import getConfig from 'shared/config/getConfig'
import styles from 'shared/theme/vars/vars.js'
import Intercom from 'react-intercom'
import enhancer from './enhancer'
import Sidebar from '../Sidebar'
import { Layout } from 'antd'
import React from 'react'

const { intercomId } = getConfig()

const AppLayout = props => {
  const {
    showSidebar = true,
    onCreateModal,
    pathname,
    children,
    profile,
    logout,
    uid
  } = props

  const [qSnap, loading] = useFirestore(classesForUser(uid))
  const classes = loading ? [] : qSnap.docs.map(doc => doc.data())
  const showIntercom = !['student'].includes(profile.role)

  return (
    <Layout>
      {showIntercom && (
        <Intercom
          appID={intercomId}
          {...formatIntercomUser({ ...profile, id: uid })} />
      )}
      <Switch>
        <Route path='/class/:classId/module/' />
        {!showSidebar ? (
          <Route path='**' render={() => <HomeHeader />} />
        ) : (
          <Route
            path='**'
            render={() => (
              <Layout.Sider
                className='main-sider'
                width={styles['@sidebar-width']}>
                <Sidebar
                  onCreateModal={onCreateModal}
                  pathname={pathname}
                  uid={uid}
                  profile={profile}
                  logout={logout}
                  classes={classes} />
              </Layout.Sider>
            )} />
        )}
      </Switch>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  )
}

AppLayout.propTypes = {}

export default enhancer(AppLayout)
export { AppLayout }
