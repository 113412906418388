import Field, { SelectField } from 'components/Field'
import { Modal, Icon } from 'antd'
import { classesBySchool } from 'shared/firestore/refs'
import useFirestore from 'shared/hooks/firestore'
import { isPD } from 'shared/utils'
import enhancer from './enhancer'
import React from 'react'

import './JoinClassModal.less'

const JoinClassModal = ({
  handleSubmit,
  stepModal,
  onCancel,
  district,
  school,
  uid,
  ...rest
}) => {
  const [qSnap, loading] = useFirestore(
    classesBySchool({ id: school, district })
  )
  if (loading) return <span />
  const classes = qSnap.docs
    .map(doc => doc.data())
    .filter(cls => {
      return !cls.members.includes(uid) && !isPD(cls)
    })
  return (
    <Modal
      {...rest}
      onOk={handleSubmit}
      onCancel={onCancel}
      title='Join A Class'>
      <form onSubmit={handleSubmit}>
        <div style={{ padding: '20px 50px 0' }}>
          <Field
            {...rest}
            showSearch
            name='class'
            placeholder='Find a class...'
            optionFilterProp='label'
            component={SelectField}
            options={classes.map(c => ({ label: c.displayName, value: c.id }))}
            style={{ width: '100%' }} />
        </div>
        {stepModal && (
          <div style={{ textAlign: 'center', fontSize: 12 }}>
            <a onClick={stepModal.next}>
              <Icon type='plus' />
              &ensp;Create a New Class
            </a>
          </div>
        )}
      </form>
    </Modal>
  )
}

JoinClassModal.propTypes = {}

export default enhancer(JoinClassModal)
export { JoinClassModal }
