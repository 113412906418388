import { Icon, Row, Col, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

const MenuTitle = ({ class: { id, displayName } }) => {
  return (
    <Link to={`/class/${id}/dashboard`}>
      <Tooltip title={displayName} placement='right' mouseEnterDelay={0.6}>
        <Row type='flex' justify='space-between' align='middle'>
          <Col className='ellipsis' style={{ flex: 1, paddingRight: 25 }}>
            <Icon type='book' />
            {displayName}
          </Col>
        </Row>
      </Tooltip>
    </Link>
  )
}

export default MenuTitle
