/**
 * @function getArrayStats
 * @param {Object} values
 * @returns {Object} with standardDeviation and mean
 * Gets basic statistical info about the array
 */
function getArrayStats (values) {
  const mean = getMean(values.map(val => parseFloat(val)))

  const squareDiffs = values.map(function (value) {
    const diff = value - mean
    const sqrDiff = diff * diff
    return sqrDiff
  })

  const avgSquareDiff = getMean(squareDiffs)

  const standardDeviation = Math.sqrt(avgSquareDiff)
  return {
    standardDeviation,
    mean
  }
}

/**
 * @function getMean
 * @param {Array} data
 * Gets the mean of the values provided
 */
function getMean (data) {
  var sum = data.reduce(function (sum, value) {
    return sum + value
  }, 0)

  var avg = sum / data.length
  return avg
}

export default getArrayStats
