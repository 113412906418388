import ClassLink from 'app/ClassSettingsModal/ClassLink/ClassLink'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import React from 'react'
import './ClassLinkModal.less'

const ClassLinkModal = ({ onOk, classData, ...rest }) => {
  return (
    <Modal
      title='Getting Students Logged In'
      visible
      footer={[
        <Button key='ok' type='primary' onClick={onOk}>
          OK
        </Button>
      ]}
      {...rest}
    >
      <ClassLink
        style={{ padding: 20, textAlign: 'center' }}
        classData={classData}
      />
    </Modal>
  )
}

ClassLinkModal.propTypes = {}

export default ClassLinkModal
