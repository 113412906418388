/**
 * @file lessons.js
 * @author Julius Diaz Panoriñgan
 * @author Giovanni Bonilla
 * File exports an object containing strings referring to lesson related actions
 * for passing into the 'useAnalyticsTracker' hook for analytics tracking.
 * File also exports a function that generates the set of actions that
 * trigger redux-segment middleware for lesson related analytics.
 *
 */

import { useAnalyticsTracker } from 'shared/analytics'

export const lessonEvents = {
  lessonAssignClick: 'CLICKED_ASSIGN_LESSON', // tracks click on 'Assign' button
  lessonAssignOkay: 'OKAYED_ASSIGN_LESSON', // tracks OK confirmation of lesson assignment
  lessonPlaylistPreview: 'PREVIEWED_LESSON_PLAYLIST', // tracks playlist previews (whole playlist via button)
  lessonActivityPreview: 'PREVIEWED_PLAYLIST_ACTIVITY', // tracks playlist individual activity previews
  assignedLessonView: 'VIEWED_ASSIGNED_LESSON', // tracks navigation to the current assigned lesson view
  allLessonsView: 'VIEWED_ALL_LESSONS', // tracks navigation to the all lessons view
  removeModule: 'REMOVED_MODULE', // tracks clicks on remove module
  lessonActivitiesToggled: 'LESSON_ACTIVITY_LIST_TOGGLED', // tracks toggling collapse/expand of lesson activity lists
  moduleScopeAndSequenceView: 'VIEWED_MODULE_SCOPE_AND_SEQUENCE', // tracks viewing of module scope and sequence
  lessonPlanView: 'VIEWED_LESSON_PLAN', // tracks viewing of lesson plan
  lessonSlidesView: 'VIEWED_LESSON_SLIDES', // tracks viewing of lesson slides
  lessonPlaylistProgressView: 'VIEWED_LESSON_PLAYLIST_PROGRESS', // tracks viewing of (whole, overall) playlist progress
  lessonActivityProgressView: 'VIEWED_LESSON_ACTIVITY_PROGRESS' // tracks viewing of (individual) activity progress
}

export default defaultSource => useAnalyticsTracker(lessonEvents, defaultSource)
